export const ROUTER_PATHS = {
  root: '/',
  auth: {
    root: 'auth',
    login: 'login',
    forgotPassword: {
      root: 'forgot-password',
      init: 'init',
      finish: 'finish'
    },
    signUp: 'sign-up'
  },
  requestInformation: 'request-information',
  scheduleIntroductoryLesson: 'schedule-introductory-lesson',
  dashboard: {
    root: 'dashboard'
  },
  referral: {
    root: 'referral'
  },
  users: {
    root: 'users',
    list: 'list'
  },
  profile: {
    root: 'profile',
    updateCurrentUserProfile: 'UpdateCurrentUserProfile'
  },
  error: {
    notFound: 'not-found'
  },
  help: {
    root: 'help'
  },
  document: {
    root: 'document'
  },
  roomAndLocation: {
    root: 'room-and-location'
  },
  settings: {
    root: 'settings',
    help: 'help',
    quickLink: 'quick-link',
    referral: 'referral',
    rewardSetting: 'reward-setting',
    document: 'document',
    plan: 'plan',
    groupClass: 'group-class',
    passes: 'passes',
    summerCamp: 'summer-camp',
    revenueCategory: 'revenue-category'
  },
  members: {
    root: 'members',
    instructors: 'instructors',
    students: 'students'
  },
  schedule: {
    root: 'schedule'
  },
  visits: {
    root: 'visits'
  },
  crud: {
    create: 'create',
    view: 'view',
    edit: 'edit',
    id: ':id'
  },
  scheduleClasses: {
    root: 'schedule-classes',
    groupClasses: 'group-classes',
    introductoryLesson: 'introductory-lesson',
    summerCamp: 'summer-camp'
  },
  plansAndPasses: {
    root: 'plans-and-passes'
  }
};
